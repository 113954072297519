import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bar__icons" }
const _hoisted_2 = { class: "bar__icons__icon" }
const _hoisted_3 = { class: "handover" }
const _hoisted_4 = { class: "body" }

import type { ComputedRef, Ref } from 'vue';
import { computed, onMounted, ref } from 'vue';
import { ordersServicePlugin, useAggregateOrderItems } from '@/features/orders';
import { getCustomerNote } from '@/features/orders/helpers';
import { HandoverFooter } from '@/features/handover/components';
import { useHandoverOrder } from '@/features/handover/composables/useHandoverOrder';
import type { MappedOrderItems } from '@/features/handover/constants';
import {
  AppHeader,
  Dialog,
  handoverFilters,
  InfoIcon,
  ScreenLoading,
  StatusBar,
  TabVariants,
  SlideButton,
} from '@/features/ui';
import RowFilter from '@/features/ui/components/RowFilter.vue';
import CustomerNotePopup from '@/features/ui/components/CustomerNotePopup.vue';
import HandoverSectionList from '@/features/handover/components/HandoverSectionList.vue';
import { useOverpickingThresholds } from '@/features/picking';
import { partitionHandoverItems } from '../helpers/partitionHandoverItems';


import { defineComponent } from 'vue';
import { handoverAgeVerificationPluginRegister } from '@/features/age-verification/plugins';
import { Order } from '@/features/orders';
import { errorPlugin } from '@/features/core/errors'; //TODO <script setup> notation doesnt support beforeRouteEnter hook now
import { usePerformanceTracker } from '@/features/performance-tracker';

//TODO <script setup> notation doesnt support beforeRouteEnter hook now
const __default__ = defineComponent({
  async beforeRouteEnter(to, from, next) {
    try {
      const ageVerificationPlugin = handoverAgeVerificationPluginRegister(
        to.params.id.toString(),
      );

      const { redirect } = await ageVerificationPlugin.execute({
        order: Order.from({}),
        redirect: null,
        redirectTo: to,
        isVerificationRequired: false,
        user: undefined,
        userRoles: undefined,
      });

      redirect ? next(redirect) : next();
    } catch (error) {
      errorPlugin.get().handle(error);
      next();
    }
  },
});

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  __name: 'HandoverOrder',
  props: {
  id: { type: String, required: true },
},
  setup(__props) {

const { stopTracking } = usePerformanceTracker();

const props = __props;

const {
  order,
  loading,
  currentTab,
  loadOrder,
  completeHandover,
  isHandoverOrderCancellable,
  cancelHandover,
} = useHandoverOrder();

const showPendingQtyDialog: Ref<boolean> = ref(false);
const customerNoteVisible = ref(false);
const overpickingThreshold = useOverpickingThresholds();

const saveOrder = async () => {
  if (!order.value) {
    return;
  }

  await ordersServicePlugin.get().saveOrder(order.value);
};

const onOrderComplete = async () => {
  if (isHandoverOrderCancellable()) {
    await cancelHandover();
  } else {
    await completeHandover();
  }
};

const navigationUrl = {
  name: 'handover-customer-information',
  params: { id: props.id },
};

const onChangeTab = (tab: TabVariants) => {
  currentTab.value = tab;
};

const orderItems = computed(() => order.value?.items);

const { aggregatedOrderItems } = useAggregateOrderItems(orderItems);

const partitionedOrderItems = computed(() =>
  partitionHandoverItems(
    aggregatedOrderItems.value,
    overpickingThreshold.value,
  ),
);

const filterQuantity = computed((): MappedOrderItems => {
  const changed = partitionedOrderItems.value.changed.length;
  const staged = partitionedOrderItems.value.staged.length;
  const rejected = partitionedOrderItems.value.rejected.length;

  return {
    all: changed + staged,
    changed,
    staged,
    rejected,
  };
});

onMounted(async () => {
  await loadOrder(props.id);
  stopTracking(`customer-information-to-handover-order-${props.id}`);
});

const customerNote: ComputedRef<string | undefined> = computed<
  string | undefined
>(() => getCustomerNote(order.value));

const customerFullName = computed(() => {
  const firstName = order.value?.customer?.firstName ?? '';
  const lastName = order.value?.customer?.lastName ?? '';

  if (order.value) {
    return `${firstName} ${lastName}`;
  }

  return '';
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_unref(order))
      ? (_openBlock(), _createBlock(_unref(AppHeader), { key: 0 }, {
          default: _withCtx(() => [
            _createVNode(_unref(StatusBar), {
              customerFullName: customerFullName.value,
              customerNote: customerNote.value,
              order: _unref(order),
              title: _unref(order).pickupCode,
              to: navigationUrl,
              hasBackButton: "",
              hasInfoIcon: ""
            }, {
              right: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                  _createElementVNode("div", _hoisted_2, [
                    _createVNode(_unref(InfoIcon), {
                      "data-e2e": "show-note-button",
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (customerNoteVisible.value = true))
                    })
                  ])
                ]),
                _createVNode(CustomerNotePopup, {
                  closeButtonText: 
            _ctx.$t('components.customer-note-popup.button-close.text')
          ,
                  customerFullName: customerFullName.value,
                  customerNote: customerNote.value,
                  isVisible: customerNoteVisible.value,
                  order: _unref(order),
                  onConfirm: _cache[1] || (_cache[1] = ($event: any) => (customerNoteVisible.value = false))
                }, null, 8, ["closeButtonText", "customerFullName", "customerNote", "isVisible", "order"])
              ]),
              _: 1
            }, 8, ["customerFullName", "customerNote", "order", "title"]),
            _createVNode(RowFilter, {
              currentTab: _unref(currentTab),
              filterQuantity: filterQuantity.value,
              filters: _unref(handoverFilters),
              "hidden-filters": 
        filterQuantity.value.rejected === 0 ? [_unref(TabVariants).Rejected] : []
      ,
              onChangeTab: onChangeTab
            }, null, 8, ["currentTab", "filterQuantity", "filters", "hidden-filters"])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        (_unref(loading))
          ? (_openBlock(), _createBlock(_unref(ScreenLoading), { key: 0 }))
          : (_openBlock(), _createBlock(HandoverSectionList, {
              key: 1,
              "current-tab": _unref(currentTab),
              "partitioned-order-items": partitionedOrderItems.value,
              onSave: saveOrder
            }, null, 8, ["current-tab", "partitioned-order-items"]))
      ]),
      _createVNode(_unref(HandoverFooter), null, {
        default: _withCtx(() => [
          _createVNode(_unref(SlideButton), {
            "data-e2e": "handover-complete-button",
            onCompleted: onOrderComplete
          })
        ]),
        _: 1
      }),
      _createVNode(_unref(Dialog), {
        "confirm-text": 
        _ctx.$t(
          'pages.handover-order.handover-un-submitted-quantity-dialog.confirmText',
        )
      ,
        "content-text": 
        _ctx.$t('pages.handover-order.handover-un-submitted-quantity-dialog.content')
      ,
        "is-title-centered": false,
        "title-text": 
        _ctx.$t('pages.handover-order.handover-un-submitted-quantity-dialog.title')
      ,
        visible: showPendingQtyDialog.value,
        "show-only-confirm": "",
        onConfirmed: _cache[2] || (_cache[2] = ($event: any) => (showPendingQtyDialog.value = false))
      }, null, 8, ["confirm-text", "content-text", "title-text", "visible"])
    ])
  ], 64))
}
}

})